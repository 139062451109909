import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {
  "title": "Nonenergy Minerals | How revenue works",
  "description": "The General Mining Act of 1872 regulates gold, silver, cinnabar, copper, and “other valuable deposits.” Despite some amendments, this legislation still governs extraction of many nonenergy minerals, in particular hardrock locatable minerals on federal public domain lands.",
  "includeToc": false,
  "tags": ["How revenue works", "Coal", "Process", "Resources"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Container = makeShortcode("Container");
const Grid = makeShortcode("Grid");
const BlmLogoImg = makeShortcode("BlmLogoImg");
const OnrrLogoImg = makeShortcode("OnrrLogoImg");
const MineralsBackground = makeShortcode("MineralsBackground");
const DidYouKnowCard = makeShortcode("DidYouKnowCard");
const CollapsibleContent = makeShortcode("CollapsibleContent");
const ProcessCard = makeShortcode("ProcessCard");
const GlossaryTerm = makeShortcode("GlossaryTerm");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Box bgcolor='primary.main' mdxType="Box">
      <Container maxWidth="lg" mdxType="Container">
        <Grid container alignItems='center' mdxType="Grid">
          <Grid item xs={12} sm={8} mdxType="Grid">
            <Box p={3} pl={0} mdxType="Box">
              <p><a parentName="p" {...{
                  "href": "/how-revenue-works/"
                }}>{`How revenue works /`}</a></p>

              <h1 {...{
                "id": "Nonenergy-Minerals",
                "style": {
                  "position": "relative"
                }
              }}>{`Nonenergy Minerals`}</h1>
              <p>{`The General Mining Act of 1872 regulates gold, silver, cinnabar, copper, and “other valuable deposits.” This legislation still governs extraction of many nonenergy minerals, like hardrock locatable minerals. The Minerals Leasing Act of 1920 limited the scope of the 1872 Act. It provided different regulations for energy-producing minerals like coal, oil, and natural gas. The following Acts govern mining on acquired lands: the Acquired Lands Mineral Leasing Act of 1947 and The Federal Land Policy and Management Act of 1976.`}</p>
            </Box>
          </Grid>
          <Grid container xs={12} sm={4} mdxType="Grid">
            <Box p={3} border={4} borderTop={0} borderBottom={0} borderColor='white' mdxType="Box">
              <Grid container xs={12} mdxType="Grid">
                <Grid item xs={8} mdxType="Grid">
                  <p>{`Managed and regulated by the `}<a parentName="p" {...{
                      "href": "https://www.blm.gov/"
                    }}>{`Bureau of Land Management `}<OpenInNewIcon style={{
                        fontSize: 20
                      }} mdxType="OpenInNewIcon" /></a>{` (BLM).`}</p>
                </Grid>
                <Grid item xs={4} mdxType="Grid">
                  <Box p={4} mdxType="Box"><BlmLogoImg mdxType="BlmLogoImg"></BlmLogoImg></Box>
                </Grid>
                <Grid item xs={8} mdxType="Grid">
                  <p>{`The `}<a parentName="p" {...{
                      "href": "http://www.onrr.gov/"
                    }}>{`Office of Natural Resources Revenue `}<OpenInNewIcon style={{
                        fontSize: 20
                      }} mdxType="OpenInNewIcon" /></a>{` (ONRR) manages some monetary transactions.`}</p>
                </Grid>
                <Grid item xs={4} mdxType="Grid">
                  <Box p={4} mdxType="Box"><OnrrLogoImg mdxType="OnrrLogoImg"></OnrrLogoImg></Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <MineralsBackground mdxType="MineralsBackground">
      <Grid container spacing={2} alignItems='flex-start' mdxType="Grid">
        <Grid item xs={12} sm={4} mdxType="Grid">
          <Box p={9} m={9} display={{
            xs: 'none',
            sm: 'block'
          }} mdxType="Box" />
          <Box p={8} display={{
            xs: 'block',
            sm: 'none'
          }} mdxType="Box" />
          <DidYouKnowCard mdxType="DidYouKnowCard">
            <p>{`Every year, BLM recognizes companies for fostering sustainable development practices.`}</p>
            <CollapsibleContent mdxType="CollapsibleContent">
              <p>{`The `}<a parentName="p" {...{
                  "href": "https://www.blm.gov/programs/energy-and-minerals/mining-and-minerals"
                }}>{`Sustainable Development `}<OpenInNewIcon style={{
                    fontSize: 20
                  }} mdxType="OpenInNewIcon" /></a>{` Awards honor those who are advancing sustainable mining practices in the industry.`}</p>
            </CollapsibleContent>
          </DidYouKnowCard>
        </Grid>
        <Grid item xs={12} sm={1} mdxType="Grid">
        </Grid>
        <Grid item xs={12} sm={7} mdxType="Grid">
          <Box mt={5} mdxType="Box">
            <ProcessCard step={1} name={'Plan'} mdxType="ProcessCard"> 
              <p>{`Hardrock mining on federal public domain lands takes place primarily in the western United States. Certain federal lands – such as wildlife refuges – are not open for mining.`}</p>
              <br />
Some hardrock mineral leasing occurs on federal acquired lands. The majority of hardrock mining on federal lands occurs on public domain lands through the claim-staking process. This process was originally outlined in the 1872 General Mining Act to encourage settlement in the West. Royalties are collected on the production of leases on acquired lands. Rents are generally not collected. The mineral lease and terms accompany the land when the federal government acquires it.
            </ProcessCard>
            <ProcessCard step={2} name={'Claim'} mdxType="ProcessCard">
              <p>{`If deposits are not known, interested parties can obtain prospecting permits to explore on federal lands.  Permits are granted on a first-come, first-serve basis. Claiming federal lands for hardrock mining falls into two categories. Deposits with well-defined boundaries are called “lode claims.” All other deposits are called “placer claims.” Each state has unique requirements for the process of marking the boundaries of a mining area. This is called claim staking. Once a prospector stakes a claim, the prospector documents it by filing required forms at the BLM field office and with the county clerk.`}</p>
              <br />
All mining claims are initially unpatented claims that permit only those activities necessary for exploration and mining.  Additionally, they require the claim to be worked every year. The original 1872 General Mining Act allowed miners to acquire patents to purchase mining claims on federal lands for any legal use. Prospectors could purchase federal lands for $5 per acre for lode claims and $2.50 per acre for placer claims. Congress prohibited this practice through moratorium in 1994. The moratorium has been renewed annually through the Interior Appropriations Acts.
            </ProcessCard>
            <ProcessCard step={3} name={'Explore'} mdxType="ProcessCard">
              <p>{`The exploration process for hardrock minerals depends on location. On public domain land, developers explore for minerals before staking a claim. Under the 1872 General Mining Act, developers cannot stake a claim until they discover a deposit of valuable minerals. Developers pay `}<GlossaryTerm mdxType="GlossaryTerm">{`claim-staking`}</GlossaryTerm>{` fees to BLM after making a discovery.`}</p>
              <br />
On acquired lands, developers pay a Prospecting Permit Fee to BLM and pay <GlossaryTerm mdxType="GlossaryTerm">rent</GlossaryTerm> to ONRR during the exploration phase.
            </ProcessCard>
            <ProcessCard step={4} name={'Develop'} mdxType="ProcessCard">
              <p>{`To extract hardrock minerals, miners must follow federal and local regulations. Miners cannot do any of the following without prior approval of an authorized federal official:`}</p>
              <ul>
                <li parentName="ul">{`construct any permanent structures`}</li>
                <li parentName="ul">{`construct any mobile structures`}</li>
                <li parentName="ul">{`store equipment `}</li>
              </ul>
              <p>{`Some development and production activities do not require earthmoving equipment or explosives.  For those activities, miners must submit a notice or Plan of Operations to the local or state government. Many states and counties have their own laws and regulations about environmental factors, public health, and safety standards.`}</p>
              <br />
Miners must pay an annual maintenance fee to BLM for each site. Small scale operators may file for a waiver stating that $100 worth of improvement work was completed annually.
            </ProcessCard>
            <ProcessCard step={5} name={'Decommission and reclaim'} mdxType="ProcessCard">
              <p>{`In 1981, BLM issued regulations requiring miners to remove all facilities and return the land to a sound environmental state. In 2001, BLM expanded on these regulations.  The expanded regulations require miners to provide bonds or other financial reassurances for reclaiming the land. They must do this before exploring or mining.`}</p>
            </ProcessCard>
            <ProcessCard name={'Rates and fees'} defaultExpanded={true} mdxType="ProcessCard">
              <ul>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "/how-revenue-works/revenues/#Hardrock-minerals"
                  }}>{`Nonenergy minerals rates and fees`}</a></li>
              </ul>
            </ProcessCard>
            <ProcessCard name={'Learn more'} defaultExpanded={true} mdxType="ProcessCard">
              <ul>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://www.blm.gov/sites/blm.gov/files/documents/files/PublicRoom_Mining_Claims_Brochure-2016.pdf"
                  }}>{`Mining claims and sites on federal lands (PDF) `}<OpenInNewIcon style={{
                      fontSize: 20
                    }} mdxType="OpenInNewIcon" /></a></li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://www.blm.gov/programs/energy-and-minerals/mining-and-minerals/locatable-minerals/mining-claims"
                  }}>{`Mining claims `}<OpenInNewIcon style={{
                      fontSize: 20
                    }} mdxType="OpenInNewIcon" /></a></li>
              </ul>
            </ProcessCard>
          </Box>
        </Grid>
      </Grid>
    </MineralsBackground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      